import { FIELD_TYPE_RADIO, FIELD_TYPE_TEXT } from '../util/types';

export const categories = [
  {
    option: 'Cakes',
    label: 'Cakes',
    subCategoriesArray: [
      { type: FIELD_TYPE_RADIO, value: 'cakeType', text: 'Which type of cake do you prefer?' },
      {
        type: FIELD_TYPE_RADIO,
        value: 'cakeFlavor',
        text: 'What flavor of cake would you prefer?',
      },
    ],
  },
  {
    option: 'Caterers',
    label: 'Caterers',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'cuisineType',
        text: 'Which style of cuisine do you prefer?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'mealServiceStyle',
        text: 'Which meal service do you prefer?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'catererProvide',
        text: 'Which of the following do you need the caterer to provide?',
      },
    ],
  },
  {
    option: 'Dance Choreographers',
    label: 'Dance Choreographers',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'danceType',
        text: 'Which dance style do you prefer?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'danceExperiencelevel',
        text: 'What is your current level of experience?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'danceExperiencelessons',
        text: 'Which kind(s) of lessons would you consider?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'dancetime',
        text: 'What time of day works best for you to attend dance lessons?',
      },
    ],
  },
  {
    option: 'Dance Performers',
    label: 'Dance Performers',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'dancePerformer',
        text: 'Which type of dance performers do you prefer?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'danceDuration',
        text: '  How long do you need the dance performers?',
      },
    ],
  },
  {
    option: 'Decorators',
    label: 'Decorators',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'decorElements',
        text: 'Are there specific decor elements you are interested in?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'decorSpecificArea',
        text:
          "Are there specific areas you'd like to highlight with decor, such as the entrance or a stage?",
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'decorTheme',
        text: 'What is the theme for this event (if any)?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'textDecor',
        text:
          'Are there any specific colors, motifs, or design elements you would like to incorporate?',
      },
    ],
  },
  {
    option: 'DJs',
    label: 'DJs',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'djTypes',
        text: ' Which type of music genre do you prefer?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'equipments',
        text: 'What equipment or other services would you require?',
      },
    ],
  },

  {
    option: 'Entertainers',
    label: 'Entertainers',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'performerType',
        text: 'Which type of entertainment do you prefer?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'guestAge',
        text: 'What is the age range of the guests at the event?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'performanceTime',
        text: 'How long do you need the entertainment for?',
      },
    ],
  },
  {
    option: 'Florists',
    label: 'Florists',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'flowerTypes',
        text: 'Which type(s) of flowers would you like to use?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'floralStyle',
        text: 'How would you describe your preferred floral style?',
      },
    ],
  },
  {
    option: 'Invitations',
    label: 'Invitations',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'invitationStyle',
        text: 'Do you have a preferred style for the invitations?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'invitationType',
        text: 'What kind of inivitation style are you interested in?',
      },
    ],
  },
  {
    option: 'Rentals',
    label: 'Rentals',
    subCategoriesArray: [
      // { type: FIELD_TYPE_RADIO,
      //   value: 'eventRental',
      //   text: 'What type of event rentals is this for?' },
      {
        type: FIELD_TYPE_RADIO,
        value: 'rentalGuestNumber',
        text: 'Approximate Number of Guests',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'rentalSupplies',
        text: 'What rentals are required for your event?',
      },
    ],
  },
  {
    option: 'Hairstylists',
    label: 'Hairstylists',
    subCategoriesArray: [
      { type: FIELD_TYPE_RADIO, value: 'hairStyle', text: 'Who is the hair stylist for? ' },
      {
        type: FIELD_TYPE_RADIO,
        value: 'trialSession',
        text: 'Would you like to schedule a hair trial session before the event? ',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'personCount',
        text: 'How many people will need hairstyling services, including yourself?',
      },
    ],
  },
  {
    option: 'Makeup',
    label: 'Makeup',
    subCategoriesArray: [
      { type: FIELD_TYPE_RADIO, value: 'weddingMakeup', text: 'What makeup style do you prefer?' },
      {
        type: FIELD_TYPE_RADIO,
        value: 'makeupPerson',
        text: 'How many people need makeup in total, including yourself?',
      },
    ],
  },
  {
    option: 'Mehndi Artists',
    label: 'Mehndi Artists',
    subCategoriesArray: [
      { type: FIELD_TYPE_RADIO, value: 'needbridalMehndi', text: ' Do you need bridal mehndi?' },
      {
        type: FIELD_TYPE_RADIO,
        value: 'mehndiNumbers',
        text: 'How many people, including yourself, need mehndi?',
      },
    ],
  },

  {
    option: 'Musicians',
    label: 'Musicians',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'music',
        text: 'What type of musician would you like to choose for your event?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'musicCategory',
        text: 'What genre of music would you like?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'musicAge',
        text: 'What is the age range of the guests at this event?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'musicDuration',
        text: ' How long do you need the music performer?',
      },
    ],
  },
  {
    option: 'Officiants/Priests',
    label: 'Officiants/Priests',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'religiousOfficiants',
        text: 'What type of officiant do you need?',
      },
      // { type: FIELD_TYPE_RADIO, value: 'text', text: 'What is the style of the Ceremony Style' },
      {
        type: FIELD_TYPE_RADIO,
        value: 'rehersal',
        text: ' Do you want the officiant to be available for the rehersal ceremony?',
      },
    ],
  },
  {
    option: 'Party Favors',
    label: 'Party Favors',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'flavorsTypes',
        text: 'What type of Party favors are you interested in?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'flavorsNumbers',
        text: 'How many Party favors would you need?',
      },
    ],
  },

  {
    option: 'Photobooths',
    label: 'Photobooths',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'photoboothsType',
        text: 'What type of photobooth are you interested in?',
      },
      { type: FIELD_TYPE_RADIO, value: 'photoboothsDuration', text: 'Duration of service (hours)' },
      // {
      //   type: FIELD_TYPE_RADIO,
      //   value: 'photoboothsOperator',
      //   text: 'Do you want an attended or an operator to set up/operate and take down?',
      // },
    ],
  },
  {
    option: 'Photographers',
    label: 'Photographers',
    subCategoriesArray: [
      { type: FIELD_TYPE_RADIO, value: 'types', text: 'Which type of photography do you prefer' },
      {
        type: FIELD_TYPE_RADIO,
        value: 'photoFormats',
        text: '  Which formats do you want your images',
      },
    ],
  },
  {
    option: 'Transportations',
    label: 'Transportations',
    subCategoriesArray: [
      { type: FIELD_TYPE_RADIO, value: 'eventTransportation', text: 'How many cars are needed?' },
      {
        type: FIELD_TYPE_RADIO,
        value: 'transportationType',
        text: 'Which types of car would you consider?',
      },
    ],
  },
  {
    option: 'Venues',
    label: 'Venues',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'eventVenues',
        text: 'Which type(s) of venue would you consider?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'venuesServices',
        text: 'Which services do you need the venue to provide?',
      },
    ],
  },
  {
    option: 'Videographers',
    label: 'Videographers',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'videoType',
        text: 'Which type(s) of video would you like?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'eventfilming',
        text: 'Which events will you need filming for?',
      },
    ],
  },
  {
    option: 'Wedding/Event Planners',
    label: 'Wedding/Event Planners',
    subCategoriesArray: [
      {
        type: FIELD_TYPE_RADIO,
        value: 'weddingPlanner',
        text: 'Which event planning service(s) do you need?',
      },
      {
        type: FIELD_TYPE_RADIO,
        value: 'weddingPlannerGuest',
        text: 'Approximately how many wedding guests is this for?',
      },
    ],
  },
];

export const allSubCtegories = {
  types: [
    { option: 'Pre-Wedding', label: 'Pre-Wedding' },
    { option: 'Wedding day', label: 'Wedding day' },
    { option: 'Aerial-&-Drone', label: 'Aerial & Drone' },
    { option: 'Birthday', label: 'Birthday' },
    { option: 'Bridal Shower', label: 'Bridal Shower' },
    { option: 'typesOther', label: 'Other' },
  ],
  photoFormats: [
    { option: 'Digital gallery ', label: 'Digital gallery ' },
    { option: 'Physical copies ', label: 'Physical copies ' },
    { option: 'Ability-to-purchase-prints-online', label: ' Ability to purchase prints online' },
    { option: 'Mix of physical and digital copies ', label: 'Mix of physical and digital copies ' },
    { option: 'photoFormatsOther', label: 'Other' },
  ],

  //  #####################################################################################################################################################################

  weddingMakeup: [
    { option: 'Natural', label: 'Natural' },
    { option: 'Glamorous', label: 'Glamorous' },
    { option: 'Bold', label: 'Bold' },
    { option: 'weddingMakeupOther', label: 'Other' },
  ],

  makeupPerson: [
    { option: 'Myself only', label: 'Myself only' },
    { option: '2', label: '2' },
    { option: '3', label: '3' },
    { option: '4', label: '4' },
    { option: '5', label: '5' },
    { option: 'makeupPersonOther', label: 'Other' },
  ],

  //#######################################################################################################################################################################

  hairStyle: [
    { option: 'Bride', label: 'Bride' },
    { option: 'Groom', label: 'Groom' },
    { option: 'Bridal party', label: 'Bridal party' },
    { option: 'Birthday girl', label: 'Birthday girl' },
    { option: 'Birthday boy', label: 'Birthday boy' },
    { option: 'hairStyleOther', label: 'Other' },
  ],

  trialSession: [
    { option: 'HairStyleYes', label: 'Yes' }, 
    { option: 'HairStyleNo', label: 'No' }],

  personCount: [
    { option: 'Myself-only', label: 'Myself Only' },
    { option: 'Two', label: 'Two' },
    { option: 'Three', label: 'Three' },
    { option: 'Four', label: 'Four' },
    { option: 'Five', label: 'Five' },
    { option: 'personCountOther', label: 'Other' },
  ],
  // #######################################################################################################################################################################

  djTypes: [
    { option: 'Hip-hop', label: 'Hip-hop' },
    { option: 'Pop', label: 'Pop' },
    { option: 'Bollywood', label: 'Bollywood' },
    { option: 'Punjabi', label: 'Punjabi' },
    { option: 'Rock', label: 'Rock' },
    { option: 'djTypesOther', label: 'Other' },
  ],

  equipments: [
    { option: 'MC', label: 'MC' },
    { option: 'Sound/PA system', label: 'Sound/PA system' },
    { option: 'Microphones', label: 'Microphones' },
    { option: 'Lighting', label: 'Lighting' },
    { option: 'Fog machine', label: 'Fog machine' },
    { option: 'Projector', label: 'Projector' },
    { option: 'Karaoke services', label: 'Karaoke services' },
    { option: "I'm not sure yet", label: "I'm not sure yet" },
    { option: 'equipmentsOther', label: 'Other' },
  ],
  // #############################################################################################################################################################################

  weddingPlanner: [
    { option: 'Planning', label: 'Planning' },
    { option: 'Decorating', label: 'Decorating' },
    { option: 'Setup', label: 'Setup' },
    { option: 'Day of Coordinator', label: 'Day of Coordinator' },
    { option: 'Catering', label: 'Catering' },
    { option: 'Server(s)', label: 'Server(s)' },
    { option: 'Entertainment', label: 'Entertainment' },
    { option: 'Clean up', label: 'Clean up' },
  ],

  weddingPlannerGuest: [
    { option: 'Less than 10 guests', label: 'Less than 10 guests' },
    { option: '10 - 30 guests', label: '10 - 30 guests' },
    { option: '30 - 60 guests', label: '30 - 60 guests' },
    { option: '60 - 100 guests', label: '60 - 100 guests' },
    { option: '100 - 299 guests', label: '100 - 299 guests' },
    { option: 'weddingPlannerGuestOther', label: 'Other' },
  ],

  eventPlanner: [
    { option: 'Planning', label: 'Planning' },
    { option: 'Decorating', label: 'Decorating' },
    { option: 'Setup', label: 'Setup' },
    { option: 'Event Manager on Day', label: 'Event Manager on Day' },
    { option: 'Catering', label: 'Catering' },
    { option: 'Server(s)', label: 'Server(s)' },
    { option: 'Entertainment', label: 'Entertainment' },
    { option: 'Clean up', label: 'Clean up' },
  ],

  eventPlannerGuest: [
    { option: 'Less than 20 guests', label: 'Less than 20 guests' },
    { option: '20 - 49 guests', label: '20 - 49 guests' },
    { option: '50 - 99 guests', label: '50 - 99 guests' },
    { option: '100 - 199 guests', label: '100 - 199 guests' },
    { option: '200 - 299 guests', label: '200 - 299 guests' },
    { option: '300 - 399 guests', label: '300 - 399 guests' },
    { option: 'eventPlannerGuestOther', label: 'Other' },
  ],

  // ##########################################################################################################################################################################

  flowerTypes: [
    { option: 'Artificial/silk', label: 'Artificial/silk' },
    { option: 'Hydrangeas', label: 'Hydrangeas' },
    { option: 'Lilies', label: 'Lilies' },
    { option: 'Orchids', label: 'Orchids' },
    { option: 'Peonies', label: 'Peonies' },
    { option: 'Ranunculus', label: 'Ranunculus' },
    { option: 'Roses', label: 'Roses' },
    { option: 'Tulips', label: 'Tulips' },
    { option: 'As recommended by the vendor', label: 'As recommended by the vendor' },
    { option: 'flowerTypesOther', label: 'Other' },
  ],

  floralStyle: [
    { option: 'Romantic', label: 'Romantic' },
    { option: 'Modern', label: 'Modern' },
    { option: 'Rustic', label: 'Rustic' },
    { option: ' Vibrant', label: ' Vibrant' },
    { option: 'floralStyleOther', label: 'Other' },
  ],
  // ##########################################################################################################################################################################

  cuisineType: [
    { option: 'Western', label: 'Western' },
    { option: 'North Indian', label: 'North Indian' },
    { option: ' Italian ', label: ' Italian ' },
    { option: ' South-Indian', label: ' South Indian' },
    { option: ' Pakistani', label: ' Pakistani' },
    ,
    { option: 'cuisineTypeOther', label: 'Other' },
  ],

  mealServiceStyle: [
    { option: 'Buffet', label: 'Buffet' },
    { option: 'Plated meal', label: 'Plated meal' },
    { option: 'Canapés/finger food', label: 'Canapés/finger food' },
    { option: ' Drinks', label: ' Drinks' },
    { option: ' Servers', label: 'Servers ' },
    { option: 'mealServiceStyleOther', label: 'Other' },
  ],

  catererProvide: [
    { option: 'Breakfast', label: 'Breakfast' },
    { option: 'Brunch', label: 'Brunch' },
    { option: 'Lunch', label: 'Lunch' },
    { option: 'Appetizers', label: 'Appetizers' },
    { option: 'Dinner', label: 'Dinner' },
    { option: 'Drinks (non-alcoholic)', label: 'Drinks (non-alcoholic)' },
    { option: 'Drinks (alcoholic)', label: 'Drinks (alcoholic)' },
    { option: 'catererProvideOther', label: 'Other' },
  ],
  // ###########################################################################################################################################################################

  videoType: [
    { option: 'Full length video (c. 90 mins)', label: 'Full length video (c. 90 mins)' },
    { option: 'Mid length video (30 to 40 minutes)', label: 'Mid length video (30 to 40 minutes)' },
    { option: 'Highlight video (3 to 4 minutes)', label: 'Highlight video (3 to 4 minutes)' },
    {
      option: 'I would like to discuss with the Pro',
      label: 'I would like to discuss with the Pro',
    },
    { option: 'videoTypeOther', label: 'Other' },
  ],

  eventfilming: [
    { option: 'Day of event only', label: 'Day of event only' },
    { option: 'Multiple events', label: 'Multiple events' },
    { option: 'Not sure', label: 'Not sure' },
    { option: 'eventfilmingOther', label: 'Other' },
  ],
  // #########################################################################################################################################################################
  eventTransportation: [
    { option: '1', label: '1' },
    { option: '2', label: '2' },
    { option: '3-or-more', label: '3 or more' },
    { option: 'eventTransportationOther', label: 'Other' },
  ],

  transportationType: [
    { option: 'Classic', label: 'Classic' },
    { option: 'Modern-luxury-car', label: 'Modern luxury car' },
    { option: 'Limo', label: 'Limo' },
    { option: 'Vintage', label: 'Vintage' },
    { option: 'Not sure yet', label: 'Not sure yet' },
    { option: 'transportationTypeOther', label: 'Other' },
  ],

  //##########################################################################################################################################################################
  eventVenues: [
    { option: 'Hotel', label: 'Hotel' },
    { option: 'Church', label: 'Church' },
    { option: 'Indoor', label: 'Indoor' },
    { option: 'Castle', label: 'Castle' },
    { option: 'Restuarants', label: 'Restuarants' },
    { option: 'Outdoor', label: 'Outdoor' },
    { option: 'Community Centre', label: 'Community Centre' },
    { option: 'Not sure', label: 'Not sure' },
    { option: 'eventVenuesOther', label: 'Other' },
  ],

  venuesServices: [
    { option: 'Catering', label: 'Catering' },
    { option: 'MC(Master of ceremonies)', label: 'MC(Master of ceremonies)' },
    { option: 'Sound and lighting', label: 'Sound and lighting' },
    { option: 'DJ/Entertainment', label: 'DJ/Entertainment' },
    { option: 'All inclusive', label: 'All inclusive' },
    { option: 'Photography', label: 'Photography' },
    { option: 'venueServicesOther', label: 'Other' },
  ],

  //##########################################################################################################################################################################

  performerType: [
    { option: 'Acrobatic Gymnastics and Circus', label: 'Acrobatic, Gymnastics and Circus' },
    { option: 'Balloon Artists', label: 'Balloon Artists' },
    { option: 'Comedians', label: 'Comedians' },
    { option: 'Costume characters', label: 'Costume characters' },
    { option: 'Caricaturist', label: 'Caricaturist' },
    { option: 'Magicians', label: 'Magicians' },
    { option: "I'm open to suggestions", label: "I'm open to suggestions" },
    { option: 'performerTypeOther', label: 'Other' },
  ],

  guestAge: [
    { option: 'Younger than 18 years', label: 'Younger than 18 years' },
    { option: '18-34', label: '18-34' },
    { option: '35-54', label: '35-54' },
    { option: '55+', label: '55+' },
    { option: 'guestAgeOther', label: 'Other' },
  ],

  performanceTime: [
    { option: '1 hour or less', label: '1 hour or less' },
    { option: '2 hour(s)', label: '2 hour(s)' },
    { option: '3 hour(s)', label: '3 hour(s)' },
    { option: '4 hour(s)', label: '4 hour(s)' },
    { option: 'More than 4 hour(s)', label: 'More than 4 hour(s)' },
    { option: 'performanceTimeOther', label: 'Other' },
  ],

  //musicians category
  music: [
    { option: 'Live Band', label: 'Live Band' },
    { option: 'Qawwali Groups', label: 'Qawwali Groups' },
    { option: 'Folk Music Ensembles', label: 'Folk Music Ensembles' },
    { option: 'Ghazal Singers', label: 'Ghazal Singers' },
    { option: 'Fusion Bands', label: 'Fusion Bands' },
    { option: 'Orchestra', label: 'Orchestra' },
    { option: 'musicOther', label: 'Other' },
  ],
  musicCategory: [
    { option: 'Bollywood', label: 'Bollywood' },
    { option: 'Bhangra', label: 'Bhangra' },
    { option: 'Classical', label: 'Classical' },
    { option: 'Ghazal', label: 'Ghazal' },
    { option: 'Sufi', label: 'Sufi' },
    { option: 'Western Music Fusion', label: 'Western Music Fusion' },
    { option: 'Rock', label: 'Rock' },
    { option: 'Pop', label: 'Pop' },
    { option: 'Jazz', label: 'Jazz' },
    { option: 'Hip Hop', label: 'Hip Hop' },
    { option: 'musicCategoryOther', label: 'Other' },
  ],
  musicAge: [
    { option: 'Younger than 18', label: 'Younger than 18' },
    { option: '18-35', label: '18-35' },
    { option: '36-54', label: '36-54' },
    { option: '55 plus', label: '55 plus' },
    { option: 'musicAgeOther', label: 'Other' },
  ],
  musicDuration: [
    { option: '1 hour or less', label: '1 hour or less' },
    { option: '2 hours', label: '2 hours' },
    { option: '3 hours', label: '3 hours' },
    { option: '4 hours', label: '4 hours' },
    { option: 'More than 4 hours', label: 'More than 4 hours' },
    { option: 'musicDurationOther', label: 'Other' },
  ],

  //dance
  dancePerformer: [
    { option: 'Bollywood Dancers', label: 'Bollywood Dancers' },
    { option: 'Bhangra Teams', label: 'Bhangra Teams' },
    { option: 'Classical Dancers', label: 'Classical Dancers' },
    { option: 'Garba/Raas Dancers', label: 'Garba/Raas Dancers' },
    { option: 'Fusion Dance Groups', label: 'Fusion Dance Groups' },
    { option: 'Contemporary Dance Troupes', label: 'Contemporary Dance Troupes' },
    { option: 'dancePerformerOther', label: 'Other' },
  ],
  // danceAge: [
  //   { option: 'Younger than 18 for dance', label: 'Younger than 18 for dance' },
  //   { option: '18-35(for dance)', label: '18-35(for dance)' },
  //   { option: '36-54(for dance)', label: '36-54(for dance)' },
  //   { option: '55 plus(for dance)', label: '55 plus(for dance)' },
  //   { option: 'danceAgeOther', label: 'Other' },
  // ],
  danceDuration: [
    { option: '1 hour or less', label: '1 hour or less' },
    { option: '2 hours', label: '2 hours' },
    { option: '3 hours', label: '3 hours' },
    { option: '4 hours', label: '4 hours' },
    { option: 'More than 4 hours', label: 'More than 4 hours' },
    { option: 'danceDurationOther', label: 'Other' },
  ],

  // ##########################################################################################################################################################################

  decorElements: [
    { option: 'Lighting', label: 'Lighting' },
    { option: 'Chair decorations', label: 'Chair decorations' },
    { option: 'Table decorations', label: 'Table decorations' },
    { option: 'Drapes and wall coverings', label: 'Drapes and wall coverings' },
    { option: 'Stage set up', label: 'Stage set-up' },
    { option: 'decorElementsOther', label: 'Other' },
  ],

  textDecor: [{ option: 'textDecorOther', label: 'Other' }],

  decorSpecificArea: [
    { option: 'Stage', label: 'Stage' },
    { option: 'Entrance', label: 'Entrance' },
    { option: 'Centre-pieces', label: 'Centre pieces' },
    { option: 'Wall-decor', label: 'Wall decor' },
    { option: 'decorSpecificAreaOther', label: 'Other' },
  ],
  decorTheme: [
    { option: "I'd like to discuss with the pro", label: "I'd like to discuss with the pro" },
    { option: 'decorThemeOther', label: 'Other' },
    ,
  ],

  // ##########################################################################################################################################################################

  danceType: [
    { option: 'Hip-hop', label: 'Hip-hop' },
    { option: 'Latin/salsa', label: 'Latin/salsa' },
    { option: 'Ballroom', label: 'Ballroom' },
    { option: 'Contemporary/modern', label: 'Contemporary/modern' },
    { option: 'Ballet', label: 'Ballet' },
    { option: 'Jazz', label: 'Jazz' },
    { option: 'Bollywood ', label: 'Bollywood ' },
    { option: 'danceTypeOther', label: 'Other' },
  ],
  danceExperiencelevel: [
    { option: 'Beginner', label: 'Beginner' },
    { option: 'Intermediate', label: 'Intermediate' },
    { option: 'Advanced', label: 'Advanced' },
    { option: 'danceExperiencelevelOther', label: 'Other' },
  ],
  danceExperiencelessons: [
    { option: 'Private 1:1 lessons', label: 'Private 1:1 lessons' },
    { option: 'Group lessons', label: 'Group lessons' },
    { option: 'danceExperiencelessonsOther', label: 'Other' },
  ],

  dancetime: [
    { option: 'Weekday Evenings', label: 'Weekday Evenings' },
    { option: 'Weekend Mornings', label: 'Weekend Mornings' },
    { option: 'Weekend Afternoons', label: 'Weekend Afternoons' },
    { option: 'Weekend Evenings', label: 'Weekend Evenings' },
    { option: 'Flexible for any day', label: 'Flexible for any day' },
    { option: 'dancetimeOther', label: 'Other' },
  ],
  // ##########################################################################################################################################################################
  invitationStyle: [
    { option: 'Classic', label: 'Classic' },
    { option: 'Modern', label: 'Modern' },
    { option: 'Whimsical', label: 'Whimsical' },
    { option: 'Vintage', label: 'Vintage' },
    { option: 'invitationStyleOther', label: 'Other' },
  ],

  invitationType: [
    { option: 'Traditional paper invites', label: 'Traditional paper invites' },
    { option: 'Digital invitations', label: 'Digital invitations' },
    { option: 'Combination of both', label: 'Combination of both' },
    { option: 'invitationTypeOther', label: 'Other' },
  ],

  // ##########################################################################################################################################################################
  flavorsTypes: [
    {
      option: 'Personalized items (i.e. candles, soaps, etc.)',
      label: 'Personalized items (i.e. candles, soaps, etc.)',
    },
    { option: 'DIY favor materials', label: 'DIY favor materials' },
    { option: 'Kids party favors ', label: 'Kids party favors ' },
    { option: 'favorsTypesOther', label: 'Other' },
  ],

  flavorsNumbers: [
    { option: '20-or-Less', label: '20 or Less' },
    { option: '50-or-Less', label: '50 or Less' },
    { option: '100-or-Less', label: '100 or Less' },
    { option: 'favorsNumbersOther', label: 'Other' },
  ],

  // ##########################################################################################################################################################################
  cakeType: [
    { option: 'Traditional tiered', label: 'Traditional tiered' },
    { option: 'Textured buttercream', label: 'Textured buttercream' },
    { option: 'Naked/semi-naked', label: 'Naked/semi-naked' },
    { option: 'Single tiered ', label: 'Single tiered ' },
    { option: 'DIY Dessert Bar ', label: 'DIY Dessert Bar ' },
    { option: 'Donuts', label: 'Donuts' },
    { option: 'Cupcakes', label: 'Cupcakes' },
    { option: 'Pastries', label: 'Pastries' },
    { option: 'Gluten-free', label: 'Gluten-free' },
    { option: 'cakeTypeOther', label: 'Other' },
  ],
  cakeFlavor: [
    { option: 'Vanilla', label: 'Vanilla' },
    { option: 'Chocolate', label: 'Chocolate' },
    { option: 'Lemon', label: 'Lemon' },
    { option: 'Red Velvet', label: 'Red Velvet' },
    { option: 'cakeFlavorOther ', label: 'Other ' },
  ],

  // ##########################################################################################################################################################################
  photoboothsType: [
    { option: 'Classic enclosed photobooth ', label: 'Classic enclosed photobooth ' },
    { option: 'Open air photo booth', label: 'Open air photo booth ' },
    { option: 'Mirror photobooth ', label: 'Mirror photobooth ' },
    { option: '360 degree photo booth ', label: '360 degree photo booth ' },
    { option: 'Green screen photo booth ', label: 'Green screen photo booth  ' },
    { option: 'Selfie station', label: 'Selfie station' },
    { option: '360-degree-rotating-camera ', label: '360 degree rotating camera ' },
    { option: 'photoboothsTypeOther', label: 'Other' },
  ],

  photoboothsDuration: [
    { option: '1-2', label: '1-2' },
    { option: '2-4', label: '2-4' },
    { option: '4-8', label: '4-8' },
    { option: '8+', label: '8+' },
    { option: 'photoboothsDurationOther', label: 'Other' },
  ],

  // ##########################################################################################################################################################################
  religiousOfficiants: [
    { option: 'Priest,', label: 'Priest' },
    { option: 'Minister', label: 'Minister' },
    { option: 'Rabbi', label: 'Rabbi' },
    { option: 'Imam', label: 'Imam' },
    { option: 'Pastor', label: 'Pastor' },
    { option: 'Pandit', label: 'Pandit' },
    { option: 'Other ordained individuals', label: 'Other ordained individual' },
  ],

  // text: [{ option: 'textOther', label: 'Other', type: FIELD_TYPE_RADIO }],

  rehersal: [
    { option: 'RehersalYes', label: 'Yes' },
    { option: 'RehersalNo', label: 'No' },
    { option: 'Unsure at this time ', label: 'Unsure at this time ' },
  ],

  // ##########################################################################################################################################################################

  needbridalMehndi: [
    { option: 'Yes', label: 'Yes' },
    { option: 'No', label: 'No' },
    { option: 'Undecided  ', label: 'Undecided  ' },
  ],

  mehndiNumbers: [
    { option: 'Myself only ', label: 'Myself only' },
    { option: '2', label: '2' },
    { option: '3-5', label: '3-5' },
    { option: '5-8', label: '5-8' },
    { option: 'More than 8 people', label: 'More than 8 people' },
    { option: 'mehndiNumbersOther', label: 'Other' },
  ],

  eventRental: [
    { option: 'Wedding', label: 'Wedding' },
    { option: "Children's birthday party", label: "Children's birthday party" },
    { option: 'Teen birthday party', label: 'Teen birthday party' },
    { option: 'Adult birthday party', label: 'Adult birthday party' },
    { option: 'Baby Shower', label: 'Baby Shower' },
    { option: 'Corporate event', label: 'Corporate event' },
    { option: 'eventRentalOther', label: 'Other' },
  ],

  rentalGuestNumber: [
    { option: 'Less than 10 guests', label: 'Less than 10 guests' },
    { option: '10-19 guests', label: '10-19 guests' },
    { option: '20-49 guests', label: '20-49 guests' },
    { option: '50-99 guests', label: '50-99 guests' },
    { option: '100-199 guests', label: '100-199 guests' },
    { option: '200-299 guests', label: '200-299 guests' },
    { option: '300-399 guests', label: '300-399 guests' },
    { option: '400 or more guests', label: '400 or more guests' },
    { option: 'rentalGuestNumberOther', label: 'Other' },
  ],

  rentalSupplies: [
    { option: 'Centerpieces', label: 'Centerpieces' },
    { option: 'Chair covers', label: 'Chair covers' },
    { option: 'Chairs', label: 'Chairs' },
    { option: 'Dance Floor', label: 'Dance Floor' },
    { option: 'Glasswares', label: 'Glasswares' },
    { option: 'Lighting', label: 'Lighting' },
    { option: 'Tablecloths', label: 'Tablecloths' },
    { option: 'Tables', label: 'Tables' },
    { option: 'Place settings (plates, cups etc.)', label: 'Place settings (plates, cups etc.)' },
    { option: 'rentalSuppliesOther', label: 'Other' },
  ],
};

export const dateSelection = [
  { option: 'Input-Calender-to-choose-date', label: 'Input Calender to choose date' },
  { option: "I'm not sure yet", label: "I'm not sure yet" },
];
export const eventTypePlanning = [
  { option: 'Birthday', label: 'Birthday' },
  { option: 'Wedding', label: 'Wedding' },
  { option: 'Bridal-Shower', label: 'Bridal Shower' },
  { option: 'Baby-Shower', label: 'Baby Shower' },
  { option: 'Corporate', label: 'Corporate' },
  { option: 'Graduation', label: 'Graduation' },
  { option: 'OtherEventTypePlanning', label: 'Other' },
];
export const eventBudget = [
  { option: "I'm not sure yet", label: "I'm not sure yet" },
  {
    option: "I'm looking for guidance from the Vendor",
    label: "I'm looking for guidance from the Vendor",
  },
  { option: 'OtherEventBudget', label: 'Other' },
];
export const hiringDecision = [
  { option: "I'm ready to hire now", label: "I'm ready to hire now" },
  {
    option: "I'm definitely planning to hire someone",
    label: "I'm definitely planning to hire someone",
  },
  { option: "I'm still researching and not sure", label: "I'm still researching and not sure" },
  { option: 'OtherHiringDecision', label: 'Other' },
];

export const availabilityVendor = [
  { option: 'I serve customers nationwide', label: 'I serve customers nationwide' },
  { option: 'I serve customers within', label: 'I serve customers within' },
];

export const specificCulture = [
  { option: 'Afghan', label: 'Afghan' },
  { option: 'Bangladeshi', label: 'Bangladeshi' },
  { option: 'Bengali', label: 'Bengali' },
  { option: 'Bhutanese', label: 'Bhutanese' },
  { option: 'Gujarati', label: 'Gujarati' },
  { option: 'Maldivian', label: 'Maldivian' },
  { option: 'Nepali', label: 'Nepali' },
  { option: 'North Indian', label: 'North Indian' },
  { option: 'Pakistani', label: 'Pakistani' },
  { option: 'Punjabi', label: 'Punjabi' },
  { option: 'South Indian', label: 'South Indian' },
  { option: 'Sri Lankan', label: 'Sri Lankan' },
  { option: 'OtherCulture', label: 'Other' },
];

export const countryNames = [
  { option: 'Canada', label: 'Canada' },
  // { option: 'Afghanistan', label: 'Afghanistan' },
  // { option: 'Åland Islands', label: 'Åland Islands' },
  // { option: 'Albania', label: 'Albania' },
  // { option: 'Algeria', label: 'Algeria' },
  // { option: 'American Samoa', label: 'American Samoa' },
  // { option: 'Andorra', label: 'Andorra' },
  // { option: 'Angola', label: 'Angola' },
  // { option: 'Anguilla', label: 'Anguilla' },
  // { option: 'Antarctica', label: 'Antarctica' },
  // { option: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
  // { option: 'Argentina', label: 'Argentina' },
  // { option: 'Armenia', label: 'Armenia' },
  // { option: 'Aruba', label: 'Aruba' },
  // { option: 'Australia', label: 'Australia' },
  // { option: 'Austria', label: 'Austria' },
  // { option: 'Azerbaijan', label: 'Azerbaijan' },
  // { option: 'Bahamas', label: 'Bahamas' },
  // { option: 'Bahrain', label: 'Bahrain' },
  // { option: 'Bangladesh', label: 'Bangladesh' },
  // { option: 'Barbados', label: 'Barbados' },
  // { option: 'Belarus', label: 'Belarus' },
  // { option: 'Belgium', label: 'Belgium' },
  // { option: 'Belize', label: 'Belize' },
  // { option: 'Benin', label: 'Benin' },
  // { option: 'Bermuda', label: 'Bermuda' },
  // { option: 'Bhutan', label: 'Bhutan' },
  // { option: 'Bolivia', label: 'Bolivia' },
  // { option: 'Bonaire, Sint Eustatius and Saba', label: 'Bonaire, Sint Eustatius and Saba' },
  // { option: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  // { option: 'Botswana', label: 'Botswana' },
  // { option: 'Bouvet Island', label: 'Bouvet Island' },
  // { option: 'Brazil', label: 'Brazil' },
  // { option: 'British Indian Ocean Territory', label: 'British Indian Ocean Territory' },
  // { option: 'Brunei Darussalam', label: 'Brunei Darussalam' },
  // { option: 'Bulgaria', label: 'Bulgaria' },
  // { option: 'Burkina Faso', label: 'Burkina Faso' },
  // { option: 'Burundi', label: 'Burundi' },
  // { option: 'Cambodia', label: 'Cambodia' },
  // { option: 'Cameroon', label: 'Cameroon' },
  // { option: 'Cape Verde', label: 'Cape Verde' },
  // { option: 'Cayman Islands', label: 'Cayman Islands' },
  // { option: 'Central African Republic', label: 'Central African Republic' },
  // { option: 'Chad', label: 'Chad' },
  // { option: 'Chile', label: 'Chile' },
  // { option: 'China', label: 'China' },
  // { option: 'Christmas Island', label: 'Christmas Island' },
  // { option: 'Cocos (Keeling) Islands', label: 'Cocos (Keeling) Islands' },
  // { option: 'Colombia', label: 'Colombia' },
  // { option: 'Comoros', label: 'Comoros' },
  // { option: 'Congo', label: 'Congo' },
  // {
  //   option: 'Congo, the Democratic Republic of the',
  //   label: 'Congo, the Democratic Republic of the',
  // },
  // { option: 'Cook Islands', label: 'Cook Islands' },
  // { option: 'Costa Rica', label: 'Costa Rica' },
  // { option: "Côte d'Ivoire", label: 'Côte d’Ivoire' },
  // { option: 'Croatia', label: 'Croatia' },
  // { option: 'Cuba', label: 'Cuba' },
  // { option: 'Curaçao', label: 'Curaçao' },
  // { option: 'Cyprus', label: 'Cyprus' },
  // { option: 'Czech Republic', label: 'Czech Republic' },
  // { option: 'Denmark', label: 'Denmark' },
  // { option: 'Djibouti', label: 'Djibouti' },
  // { option: 'Dominica', label: 'Dominica' },
  // { option: 'Dominican Republic', label: 'Dominican Republic' },
  // { option: 'Ecuador', label: 'Ecuador' },
  // { option: 'Egypt', label: 'Egypt' },
  // { option: 'El Salvador', label: 'El Salvador' },
  // { option: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  // { option: 'Eritrea', label: 'Eritrea' },
  // { option: 'Estonia', label: 'Estonia' },
  // { option: 'Ethiopia', label: 'Ethiopia' },
  // { option: 'Falkland Islands (Malvinas)', label: 'Falkland Islands (Malvinas)' },
  // { option: 'Faroe Islands', label: 'Faroe Islands' },
  // { option: 'Fiji', label: 'Fiji' },
  // { option: 'Finland', label: 'Finland' },
  // { option: 'France', label: 'France' },
  // { option: 'French Guiana', label: 'French Guiana' },
  // { option: 'French Polynesia', label: 'French Polynesia' },
  // { option: 'French Southern Territories', label: 'French Southern Territories' },
  // { option: 'Gabon', label: 'Gabon' },
  // { option: 'Gambia', label: 'Gambia' },
  // { option: 'Georgia', label: 'Georgia' },
  // { option: 'Germany', label: 'Germany' },
  // { option: 'Ghana', label: 'Ghana' },
  // { option: 'Gibraltar', label: 'Gibraltar' },
  // { option: 'Greece', label: 'Greece' },
  // { option: 'Greenland', label: 'Greenland' },
  // { option: 'Grenada', label: 'Grenada' },
  // { option: 'Guadeloupe', label: 'Guadeloupe' },
  // { option: 'Guam', label: 'Guam' },
  // { option: 'Guatemala', label: 'Guatemala' },
  // { option: 'Guernsey', label: 'Guernesey' },
  // { option: 'Guinea', label: 'Guinea' },
  // { option: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  // { option: 'Guyana', label: 'Guyana' },
  // { option: 'Haiti', label: 'Haiti' },
  // { option: 'Heard Island and McDonald Islands', label: 'Heard Island and McDonald Islands' },
  // { option: 'Holy See (Vatican City State)', label: 'Holy See (Vatican City State)' },
  // { option: 'Honduras', label: 'Honduras' },
  // { option: 'Hong Kong', label: 'Hong Kong' },
  // { option: 'Hungary', label: 'Hungary' },
  // { option: 'Iceland', label: 'Iceland' },
  // { option: 'India', label: 'India' },
  // { option: 'Indonesia', label: 'Indonesia' },
  // { option: 'Iran', label: 'Iran' },
  // { option: 'Iraq', label: 'Iraq' },
  // { option: 'Ireland', label: 'Ireland' },
  // { option: 'Isle of Man', label: 'Isle of Man' },
  // { option: 'Israel', label: 'Israel' },
  // { option: 'Italy', label: 'Italy' },
  // { option: 'Jamaica', label: 'Jamaica' },
  // { option: 'Japan', label: 'Japan' },
  // { option: 'Jersey', label: 'Jersey' },
  // { option: 'Jordan', label: 'Jordan' },
  // { option: 'Kazakhstan', label: 'Kazakhstan' },
  // { option: 'Kenya', label: 'Kenya' },
  // { option: 'Kiribati', label: 'Kiribati' },
  // {
  //   option: "Korea, Democratic People's Republic of",
  //   label: "Korea, Democratic People's Republic of",
  // },
  // { option: 'Korea, Republic of', label: 'Korea, Republic of' },
  // { option: 'Kuwait', label: 'Kuwait' },
  // { option: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  // { option: 'Laos', label: 'Laos' },
  // { option: 'Latvia', label: 'Latvia' },
  // { option: 'Lebanon', label: 'Lebanon' },
  // { option: 'Lesotho', label: 'Lesotho' },
  // { option: 'Liberia', label: 'Liberia' },
  // { option: 'Libya', label: 'Libya' },
  // { option: 'Liechtenstein', label: 'Liechtenstein' },
  // { option: 'Lithuania', label: 'Lithuania' },
  // { option: 'Luxembourg', label: 'Luxembourg' },
  // { option: 'Macao', label: 'Macao' },
  // { option: 'North Macedonia', label: 'North Macedonia' },
  // { option: 'Madagascar', label: 'Madagascar' },
  // { option: 'Malawi', label: 'Malawi' },
  // { option: 'Malaysia', label: 'Malaysia' },
  // { option: 'Maldives', label: 'Maldives' },
  // { option: 'Mali', label: 'Mali' },
  // { option: 'Malta', label: 'Malta' },
  // { option: 'Marshall Islands', label: 'Marshall Islands' },
  // { option: 'Martinique', label: 'Martinique' },
  // { option: 'Mauritania', label: 'Mauritania' },
  // { option: 'Mauritius', label: 'Mauritius' },
  // { option: 'Mayotte', label: 'Mayotte' },
  // { option: 'Mexico', label: 'Mexico' },
  // { option: 'Micronesia, Federated States of', label: 'Micronesia, Federated States of' },
  // { option: 'Moldova', label: 'Moldova' },
  // { option: 'Monaco', label: 'Monaco' },
  // { option: 'Mongolia', label: 'Mongolia' },
  // { option: 'Montenegro', label: 'Montenegro' },
  // { option: 'Montserrat', label: 'Montserrat' },
  // { option: 'Morocco', label: 'Morocco' },
  // { option: 'Mozambique', label: 'Mozambique' },
  // { option: 'Myanmar', label: 'Myanmar' },
  // { option: 'Namibia', label: 'Namibia' },
  // { option: 'Nauru', label: 'Nauru' },
  // { option: 'Nepal', label: 'Nepal' },
  // { option: 'Netherlands', label: 'Netherlands' },
  // { option: 'New Caledonia', label: 'Nouvelle-Calédonie' },
  // { option: 'New Zealand', label: 'New Zealand' },
  // { option: 'Nicaragua', label: 'Nicaragua' },
  // { option: 'Niger', label: 'Niger' },
  // { option: 'Nigeria', label: 'Nigeria' },
  // { option: 'Niue', label: 'Niue' },
  // { option: 'Norfolk Island', label: 'Norfolk Island' },
  // { option: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
  // { option: 'Norway', label: 'Norway' },
  // { option: 'Oman', label: 'Oman' },
  // { option: 'Pakistan', label: 'Pakistan' },
  // { option: 'Palau', label: 'Palau' },
  // { option: 'Palestine, State of', label: 'Palestine, State of' },
  // { option: 'Panama', label: 'Panama' },
  // { option: 'Papua New Guinea', label: 'Papua New Guinea' },
  // { option: 'Paraguay', label: 'Paraguay' },
  // { option: 'Peru', label: 'Peru' },
  // { option: 'Philippines', label: 'Philippines' },
  // { option: 'Pitcairn', label: 'Pitcairn' },
  // { option: 'Poland', label: 'Poland' },
  // { option: 'Portugal', label: 'Portugal' },
  // { option: 'Puerto Rico', label: 'Puerto Rico' },
  // { option: 'Qatar', label: 'Qatar' },
  // { option: 'Réunion', label: 'Réunion' },
  // { option: 'Romania', label: 'Romania' },
  // { option: 'Russian Federation', label: 'Russian Federation' },
  // { option: 'Rwanda', label: 'Rwanda' },
  // { option: 'Saint Barthélemy', label: 'Saint-Barthélemy' },
  // {
  //   option: 'Saint Helena, Ascension and Tristan da Cunha',
  //   label: 'Saint Helena, Ascension and Tristan da Cunha',
  // },
  // { option: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
  // { option: 'Saint Lucia', label: 'Saint Lucia' },
  // { option: 'Saint Martin (French part)', label: 'Saint Martin (French part)' },
  // { option: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
  // { option: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
  // { option: 'Samoa', label: 'Samoa' },
  // { option: 'San Marino', label: 'San Marino' },
  // { option: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
  // { option: 'Saudi Arabia', label: 'Saudi Arabia' },
  // { option: 'Senegal', label: 'Senegal' },
  // { option: 'Serbia', label: 'Serbia' },
  // { option: 'Seychelles', label: 'Seychelles' },
  // { option: 'Sierra Leone', label: 'Sierra Leone' },
  // { option: 'Singapore', label: 'Singapore' },
  // { option: 'Sint Maarten (Dutch part)', label: 'Sint Maarten (Dutch part)' },
  // { option: 'Slovakia', label: 'Slovakia' },
  // { option: 'Slovenia', label: 'Slovenia' },
  // { option: 'Solomon Islands', label: 'Solomon Islands' },
  // { option: 'Somalia', label: 'Somalia' },
  // { option: 'South Africa', label: 'South Africa' },
  // {
  //   option: 'South Georgia and the South Sandwich Islands',
  //   label: 'South Georgia and the South Sandwich Islands',
  // },
  // { option: 'South Sudan', label: 'South Sudan' },
  // { option: 'Spain', label: 'Spain' },
  // { option: 'Sri Lanka', label: 'Sri Lanka' },
  // { option: 'Sudan', label: 'Sudan' },
  // { option: 'Suriname', label: 'Suriname' },
  // { option: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
  // { option: 'Swaziland', label: 'Swaziland' },
  // { option: 'Sweden', label: 'Sweden' },
  // { option: 'Switzerland', label: 'Switzerland' },
  // { option: 'Syrian Arab Republic', label: ' Syrian Arab Republic' },
  // { option: 'Taiwan', label: 'Taiwan' },
  // { option: 'Tajikistan', label: 'Tajikistan' },
  // { option: 'Tanzania', label: 'Tanzania' },
  // { option: 'Thailand', label: 'Thailand' },
  // { option: 'Timor-Leste', label: 'Timor-Leste' },
  // { option: 'Togo', label: 'Togo' },
  // { option: 'Tokelau', label: 'Tokelau' },
  // { option: 'Tonga', label: 'Tonga' },
  // { option: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  // { option: 'Tunisia', label: 'Tunisia' },
  // { option: 'Turkey', label: 'Turkey' },
  // { option: 'Turkmenistan', label: 'Turkmenistan' },
  // { option: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
  // { option: 'Tuvalu', label: 'Tuvalu' },
  // { option: 'Uganda', label: 'Uganda' },
  // { option: 'Ukraine', label: 'Ukraine' },
  // { option: 'United Arab Emirates', label: 'United Arab Emirates' },
  // { option: 'United Kingdom', label: 'United Kingdom' },
  // { option: 'United States', label: 'United States' },
  // { option: 'United States Minor Outlying Islands', label: 'United States Minor Outlying Islands' },
  // { option: 'Uruguay', label: 'Uruguay' },
  // { option: 'Uzbekistan', label: 'Uzbekistan' },
  // { option: 'Vanuatu', label: 'Vanuatu' },
  // { option: 'Venezuela', label: 'Venezuela' },
  // { option: 'Viet Nam', label: 'Viêt Nam' },
  // { option: 'Virgin Islands, British', label: 'Virgin Islands, British' },
  // { option: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
  // { option: 'Wallis and Futuna', label: 'Wallis and Futuna' },
  // { option: 'Western Sahara', label: 'Western Sahara' },
  // { option: 'Yemen', label: 'Yemen' },
  // { option: 'Zambia', label: 'Zambia' },
  // { option: 'Zimbabwe', label: 'Zimbabwe' },
];

export const vendorServices = [
  { option: 'Cakes', label: 'Cakes' },
  { option: 'Caterers', label: 'Caterers' },
  { option: 'Dance Choreographers', label: 'Dance Choreographers' },
  { option: 'Dance Performers', label: 'Dance Performers' },
  { option: 'Decorators', label: 'Decorators' },
  { option: 'DJs', label: 'DJs' },
  { option: 'Entertainers', label: 'Entertainers' },
  { option: 'Rentals', label: 'Rentals' },
  { option: 'Florists', label: 'Florists' },
  { option: 'Hairstylists', label: 'Hairstylists' },
  { option: 'Invitations', label: 'Invitations' },
  { option: 'Makeup', label: 'Makeup' },
  { option: 'Mehndi Artists', label: 'Mehndi Artists' },
  { option: 'Musicians', label: 'Musicians' },
  { option: 'Officiants & Premarital Counseling', label: 'Officiants & Premarital Counseling' },
  { option: 'Party Favors', label: 'Party Favors' },
  { option: 'Photobooths', label: 'Photobooths' },
  { option: 'Photographers', label: 'Photographers' },
  { option: 'Transportations', label: 'Transportations' },
  { option: 'Venues for the Event', label: 'Venues for the Event' },
  { option: 'Videographers', label: 'Videographers' },
  { option: 'Wedding/Event Planners', label: 'Wedding/Event Planners' },
];

export const quantity = [
  { option: '8', label: '8' },
  { option: '9', label: '9' },
  { option: '10', label: '10' },
  { option: '11', label: '11' },
  { option: '12', label: '12' },
  { option: '13', label: '13' },
  { option: '14', label: '14' },
  { option: '15', label: '15' },
  { option: '16', label: '16' },
  { option: '17', label: '17' },
  { option: '18', label: '18' },
  { option: '19', label: '19' },
  { option: '20', label: '20' },
];
